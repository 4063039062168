import { ref, watch, computed, getCurrentInstance } from "@vue/composition-api";
import store from "@/store";
import VueI18n from "@/libs/i18n";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useNutrientsList() {
  // Use toast
  const toast = useToast();
  var ins = getCurrentInstance();

  const refNutrientListTable = ref(null);
  // Table Handlers
  const tableColumns = [
    { key: "kod", label: VueI18n.t("code"), sortable: true },
    { key: "nutrientname", label: VueI18n.t("nutrientName"), sortable: true },
    { key: "birim", label: VueI18n.t("unit"), sortable: false },
    { key: "edit", label: VueI18n.t("edit") },
    { key: "delete", label: VueI18n.t("delete") },
  ];
  const perPage = ref(10);
  const totalUsers = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const roleFilter = ref(null);
  const planFilter = ref(null);
  const statusFilter = ref(null);
  const ingredint = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refNutrientListTable.value
      ? refNutrientListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    };
  });

  const refetchData = () => {
    refNutrientListTable.value.refresh();
  };

  watch(
    [currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter],
    () => {
      refetchData();
    }
  );

  const fetchNutrients = async (ctx, callback) => {
    var nutrient = await store
      .dispatch("plantsModule/fetchNutrients", store.state.app.selectedPlantId)
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: VueI18n.t("xFetchError", { value: VueI18n.t("nutrients") }),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
    if (searchQuery.value != "")
      nutrient = nutrient.filter((x) =>
        x.nutrientname.toLowerCase().startsWith(searchQuery.value.toLowerCase())
      );
    return nutrient;
  };

  const deleteNutrient = (ctx) => {
    store
      .dispatch("plantsModule/deleteNutrient", ctx)
      .then((response) => {
        refetchData();
        toast({
          component: ToastificationContent,
          props: {
            title: VueI18n.t("successful"),
            icon: "CheckIcon",
            variant: "success",
            text: VueI18n.t("deleted", { type: VueI18n.t("nutrient") }),
          },
        });
      })
      .catch((e) => {
        var [status, message] = e.message.split("_");
        var msg = "";
        if (status == "ERROR1") msg = VueI18n.t("notAllowed");
        else if (status == "ERROR2")
          msg = VueI18n.t("useInEquations") + " - " + message;
        else msg = VueI18n.t("notDeleted", { type: VueI18n.t("nutrient") });
        ins.proxy.$bvModal.msgBoxOk(
          msg,

          {
            title: VueI18n.t("error"),
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: VueI18n.t("ok"),
            okOnly: true,
            cancelTitle: VueI18n.t("cancel"),
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        );
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchNutrients,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refNutrientListTable,
    deleteNutrient,

    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  };
}
